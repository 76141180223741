<template>
  <v-dialog
    max-width="800"
    v-model="show"
    @click:outside="$emit('close')"
  >
    <v-card>
      <v-card-title>
        {{$t('videos.choose_videos')}}
      </v-card-title>
      <v-card-text v-if="getGames">
        <div
          v-for="video in games"
          :key="'cho' + video.id"
          class="mb-2"
        >
          <v-chip
            style="cursor: pointer;"
            @click="handleClick(video)"
            :color="videoIsChosen(video.id) ? 'primary' : ''"
          >
            <v-img class="mx-2" height="20" width="20" :src="video.home_team_logo_url"></v-img>
            {{video.home_team_short_name}} - {{video.away_team_short_name}}
            <v-img class="mx-2" height="20" width="20" :src="video.away_team_logo_url"></v-img>
          </v-chip>
          {{ prettyDate(video) }}
        </div>
      </v-card-text>
      <v-card-text v-else>
        <div
          v-for="video in videos"
          :key="'cho' + video.id"
          class="mb-2"
        >
          <v-chip
            style="cursor: pointer;"
            @click="handleClick(video)"
            :color="videoIsChosen(video.id) ? 'primary' : ''"
          >
            {{video.title}}
          </v-chip>
        </div>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn @click="clear()">
          {{ $t('clear') }}
        </v-btn>
        <v-btn @click="chooseAll()">
          {{ $t('choose_all') }}
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          @click="$emit('close')"
        >
          {{$t('set')}}
          <v-chip
            small
            class="ml-2"
            style="cursor: pointer;"
          >{{value.length}}</v-chip>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  props: ['show', 'value', 'getGames'],
  created() {
    if((!this.games || !this.games.length) && this.getGames) {
      this.initGames()
        .then(() => {
          // this.$nextTick(() => {
          //   this.chooseAll()
          // })
        })
        .catch(e => this.error(e))
    } else if(!this.videos || !this.videos.length) {
      this.initVideos()
        .then(() => {
          // this.$nextTick(() => {
          //   this.chooseAll()
          // })
        })
        .catch(e => this.error(e))
    }
  },
  methods: {
    ...mapActions('admin', [
      'initGames'
    ]),
    ...mapActions('videos', [
      'initVideos'
    ]),
    ...mapActions('noti', [
      'error'
    ]),
    videoIsChosen(id) {
      if(!this.value) return false
      return this.value.map(t => t.id).includes(id)
    },
    handleClick(video) {
      let val;
      if(this.videoIsChosen(video.id)) val = [...this.value].filter(t => t.id !== video.id)
      else val = [...this.value].concat(video)
      this.$emit('input', val)
    },
    chooseAll() {
      this.$emit('input', !this.getGames ? [...this.videos] : [...this.games])
    },
    clear() {
      this.$emit('input', [])
    },
    prettyDate(game) {
      const date = new Date(game.starttime_unix)
      return date.getDate() + '.' + (date.getMonth() + 1) + '.' + date.getFullYear()
    }
  },
  computed: {
    ...mapGetters('admin', [
      'games'
    ]),
    ...mapGetters('videos', [
      'videos'
    ]),
  }
}
</script>