<template>
  <v-dialog 
    max-width="700"
    v-model="show"
    @click:outside="$emit('close')"
  >
    <v-card scrollable>
      <!-- <v-overlay
        absolute
        v-if="videosLoading"
      ></v-overlay> -->
      <v-card-title>
        {{$t('filter.new_filter')}}
      </v-card-title>
      <v-card-text :style="`overflow-y: scroll; ${$vuetify.breakpoint.mobile ? 'max-height: 60vh;': 'max-height: 70vh;'}`">
        <div class="mb-8" v-if="save">
          <v-expand-transition>
            <v-text-field
              v-prevent-keys
              v-if="save"
              class="mt-2"
              v-model="title"
              :hide-details="true"
              @input="give_name_error = false"
              :placeholder="$t('filter.filter_title')"
              solo
              :error="true"
            ></v-text-field>
          </v-expand-transition>
          <div 
            style="color: red; margin-top: 5px"
            v-if="give_name_error"
          >
            {{$t('filter.give_a_name')}}
          </div>
        </div>
        <v-switch
          class="mb-3"
          :label="$t('filter.save_filter')"
          v-model="save"
        ></v-switch>
        <v-row v-if="this.videos && this.videos.length">
          <!-- <v-switch
            v-if="filteringOwnClips && leagueId"
            v-model="filterVideosActive"
            :value="true"
            class="pt-3 ml-4 mr-2"
          ></v-switch> -->
          <v-btn
            v-if="filteringOwnClips"
            :disabled="!filterVideosActive"
            class="ml-3 mb-8"
            @click="chooseOwnVideos = true"
          >
            {{$t('filter.filter_by_videos')}}
            <v-icon class="ml-2">mdi-video-outline</v-icon>
            <v-chip
              :color="chosenOwnVideos.length > 0 ? 'primary' : ''"
              small 
              class="ml-3"
              style="cursor: pointer;"
            >{{chosenOwnVideos.length > 0 ? chosenOwnVideos.length : $t('all')}}</v-chip>
          </v-btn>
        </v-row>
        <v-row v-if="leagueId" class="mb-6" justify="center">
          <v-btn @click="chosenTags = []; filteringOwnClips = !filteringOwnClips; tagAdderKey++;" :text="filteringOwnClips" class="indigo--text" small>{{$t('filter.external_clips')}}</v-btn>
          <v-btn @click="chosenTags = []; filteringOwnClips = !filteringOwnClips; tagAdderKey++;" :text="!filteringOwnClips" class="indigo--text" small>{{$t('filter.own_clips')}}</v-btn>
        </v-row>
        <TagAdder
          :hideExtraButtons="true"
          @edit_tags="$router.push('/tags')"
          :hide_actions="true"
          :isAnalysis="!filteringOwnClips"
          :key="tagAdderKey"
          :filtering="true"
          :show="chooseTags"
          v-model="chosenTags"
          @input="choose_one_error = false"
          @close="chooseTags = false; choose_one_error = false"
        ></TagAdder>
        <!-- <v-btn
          rounded
          @click="chooseTags = true"
        >
          {{$t('filter.filter_by_tags')}}
          <v-icon class="ml-2">mdi-tag-outline</v-icon>
          <v-chip
            class="ml-3"
            small 
            style="cursor: pointer;"
          >{{chosenTags.length}}</v-chip>
        </v-btn>
        <div 
          style="color: red;"
          v-if="choose_one_error"
        >
          {{$t('filter.choose_one_tag')}}
        </div>
        <v-chip-group
          class="mb-5 ml-1"
        >
          <v-chip
            v-for="tag in chosenTags"
            :key="tag.id + 'clipgroup'"
            color="primary"
            @click="chooseTags = true"
            x-small
          >
            {{tag.tag_name}}
          </v-chip>
        </v-chip-group> -->
        <v-row v-if="!filteringOwnClips">
          <!-- <v-switch
            v-if="filteringOwnClips && leagueId"
            v-model="filterGamesActive"
            class="pt-3 ml-4"
          ></v-switch> -->
          <v-btn
            :disabled="!filterGamesActive"
            rounded
            class="mt-6 ml-3"
            @click="chooseVideos = true"
            v-if="leagueId"
          >
            {{$t('filter.filter_by_games')}}
            <v-icon class="ml-2">mdi-video-outline</v-icon>
            <v-chip
              :color="chosenVideos.length > 0 ? 'primary' : ''"
              small 
              class="ml-3"
              style="cursor: pointer;"
            >{{chosenVideos.length > 0 ? chosenVideos.length : $t('all')}}</v-chip>
          </v-btn>
        </v-row>
        <div></div>
        <div></div>
        <!-- <v-chip-group
          class="mb-5 ml-1"
        >
          <v-chip
            v-for="video in chosenVideos"
            :key="video.id + 'clipgroup'"
            color="primary"
            x-small
            @click="chooseVideos = true"
          >
            {{video.title}}
          </v-chip>
        </v-chip-group> -->
      </v-card-text>
      <v-card-actions absolute>
        <v-btn
          @click="$emit('close')"
        >
          {{$t('cancel')}}
        </v-btn>
        <v-spacer></v-spacer>
        <div 
          style="color: red;"
          v-if="choose_one_error"
          class="mr-3"
        >
          {{$t('filter.choose_tag')}}
        </div>
        <v-btn 
          color="primary"
          @click="search()"
          :loading="loading"
        >
          <v-icon>mdi-magnify</v-icon>
          {{$t('search')}}
        </v-btn>
      </v-card-actions>
    </v-card>
    <video-adder
      :getGames="true"
      :key="tagAdderKey + '1'"
      :show="chooseVideos"
      v-model="chosenVideos"
      @close="chooseVideos = false"
    >
    </video-adder>
    <video-adder
      :getGames="false"
      :key="tagAdderKey + '2'"
      :show="chooseOwnVideos"
      v-model="chosenOwnVideos"
      @close="chooseOwnVideos = false"
    >
    </video-adder>
  </v-dialog>
</template>

<script>
import TagAdder from '../tag/TagAdder.vue'
import VideoAdder from '../video/VideoAdder.vue'
import { mapGetters, mapActions } from 'vuex'
export default {
  props: ['show', 'loading'],
  data: () => ({
    videosLoading: false,
    filteringOwnClips: true,
    filterVideosActive: true,
    tagAdderKey: 0,
    chooseTags: false,
    chosenTags: [],
    chooseVideos: false,
    chooseOwnVideos: false,
    chosenVideos: [],
    chosenOwnVideos: [],
    filterGamesActive: true,
    title: '',
    save: false,
    rules: {
      required: value => !!value || 'Required.',
      min: v => v.length >= 8 || 'Min 8 characters',
      emailMatch: () => (`The email and password you entered don't match`),
    },
    give_name_error: false,
    choose_one_error: false
  }),
  created() {
    this.videosLoading = true

    if(this.leagueId) {
      this.filteringOwnClips = false
      this.tagAdderKey++
    }

    let promises = [1]
    // if(!this.games || !this.games.length) promises.push(this.initGames())
    if(!this.videos || !this.videos.length) promises.push(this.initVideos())

    Promise.all(promises)
      .catch(e => {
        this.error(e)
      })
      .finally(() => {
        this.videosLoading = false
      })

  },
  components: {
    TagAdder,
    VideoAdder
  },
  computed: {
    ...mapGetters('user', [
      'leagueId',
      'currentTeamId'
    ]),
    ...mapGetters('admin', [
      'games'
    ]),
    ...mapGetters('videos', [
      'videos'
    ]),
  },
  methods: {
    ...mapActions('noti', [
      'error'
    ]),
    ...mapActions('admin', [
      'initGames'
    ]),
    ...mapActions('videos', [
      'initVideos'
    ]),
    search() {
      if(!this.title && this.save) {
        this.give_name_error = true
        return
      }
      if(!this.chosenTags.length) {
        this.choose_one_error = true
        return
      }
      if(this.choose_one_error || this.chosen_one_error) {
        return
      }

      let params = {
        videos: this.chosenVideos.map(v => v.id).concat(this.chosenOwnVideos.map(v => v.id)),
        tags: this.chosenTags.map(t => t.id),
        search_games: !this.filteringOwnClips,
        title: this.title,
        save: this.save,
        team_id: this.currentTeamId
      }

      this.$emit('search', params)
    }
  }
}
</script>