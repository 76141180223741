<template>
  <v-card v-if="all_clips_n > 0" flat>
    <div class="text-h6 my-3">
      {{ group.group_name }}
    </div>
    <div v-for="tag in group.tags.filter(t => !t.archived)" :key="tag.id + 'tag'">
      <!-- {{ tag.tag_name }} -->
      <!-- {{ clips_by_tag_id(tag.id).length }} -->
    </div>
    <div style="max-width: 800px;">
      <v-data-table 
        :hide-default-footer="true"
        :dense="true" 
        :headers="headers"
        :items="items"
      >
        <template v-slot:item.filter_url="{ item }">
          <v-btn
            text
            x-small
            :disabled="!item.n"
            @click="$router.push(item.filter_url)"
          >
            <v-icon>mdi-play-outline</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </div>
    <v-divider></v-divider>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  props: ['group_id'],
  data: () => ({
  }),
  computed: {
    ...mapGetters('tag', [
      'tag_groups'
    ]),
    ...mapGetters('data', [
      'clips_by_tag_id',
      'current_filter'
    ]),
    group() {
      return this.tag_groups.find(g => g.id == this.group_id)
    },
    headers() {
      return [
        {text: 'Tag', value: 'tag_name'},
        {text: 'n', value: 'n'},
        {text: '%', value: '%'},
        {text: 't', value: 't'},
        {text: 't%', value: 't%'},
        {text: 'Playlist', value: 'filter_url'}
      ]
    },
    items() {
      return this.tags.map(t => {
        const n = this.clips_by_tag_id(t.id).length
        const time = this.tag_clips_t_sum(t.id)
        const all_clips_sum = this.all_clips_t_sum

        const remove_these_tags = this.tags.map(t => t.id).filter(id => id != t.id)
        const filter = {
          ...this.current_filter,
          tags: [...this.current_filter.tags.filter(id => !remove_these_tags.includes(id))].concat(t.id)
        }
        return {
          tag_name: t.tag_name,
          n,
          '%': `${n ? (100 * n / this.all_clips_n).toFixed(1) : 0} %`,
          t: `${Math.round(time)} s`,
          't%': `${all_clips_sum ? (100 * time / all_clips_sum).toFixed(1) : 0} %`,
          'filter_url': {
            'path': '/filter/nosave',
            query: {
              filter: JSON.stringify(filter),
              from: '/data'
            }
          }
        }
      })
    },
    tags() {
      return this.group.tags.filter(t => !t.archived)
    },
    all_clips_n() {
      return this.group.tags.map(t => {
        return this.clips_by_tag_id(t.id).length
      }).reduce((accumulator, currentValue) => accumulator + currentValue, 0)
    },
    all_clips_t_sum() {
      return this.group.tags.map(t => {
        return this.tag_clips_t_sum(t.id)
      }).reduce((a, b) => a + b, 0)
    }
  },
  methods: {
    tag_clips_t_sum(tag_id) {
      return this.clips_by_tag_id(tag_id).map(c => {
        return c.endtime - c.starttime
      }).reduce((a, b) => a + b, 0)
    },
  }
}
</script>