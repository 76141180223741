<template>
  <v-card :class="{ 'pa-3': inline, 'pa-10': !inline }" flat>
    <div>
      <v-btn @click="filtering = true">
        <v-icon>mdi-filter-outline</v-icon>
      </v-btn>
      <v-btn @click="show_settings = true" class="ml-2">
        <v-icon>mdi-cog-outline</v-icon>
      </v-btn>
    </div>

    <!-- {{ settings.show_groups }} -->
    <!-- {{ clips }} -->
    <v-card 
      class="mt-4" 
      style="text-align: center;" 
      flat 
      v-if="choose_filter && !clips.length"
    >
      <!-- <div class="text-h6">
        {{ $t('choose_tags_for_data') }}
      </div> -->
      <v-btn
        class="mt-8"
        color="primary"
        outlined
        @click="filtering = true"
        :loading="loading"
      >
        <v-icon 
          class="mr-4"
        >
          mdi-tag-outline
        </v-icon>
        {{ $t('time.choose_tags') }}
      </v-btn>
      <v-img class="mx-auto mt-16" height="292" width="300" src="../assets/svg/nodata.svg"></v-img>
    </v-card>
    <data-group
      v-for="group_id in settings.show_groups"
      :key="'group' + group_id"
      :group_id="group_id"
    ></data-group>


    <create-filter :loading="loading" @close="filtering = false" @search="startSearch($event)" :show="filtering"></create-filter>
    <v-dialog v-model="show_settings" max-width="800">
      <v-card class="pa-5">
        <div class="text-h4">
          Show groups
        </div>
        <v-select
          multiple
          v-model="settings.show_groups"
          item-text="group_name"
          chips
          item-value="id"
          :items="tag_groups.filter(t => !t.archived)"
        >

        </v-select>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import CreateFilter from '@/components/filters/CreateFilter.vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import DataGroup from '@/components/data/DataGroup.vue'
export default {
  components: { CreateFilter, DataGroup },
  props: ['inline'],
  created() {
    this.SET_LOADING(true)
    this.initTags()
      .then(e => {
        this.settings.show_groups = e.data.filter(e => !e.archived).map(t => t.id)
      })
      .finally(() => {
        this.SET_LOADING(false)
      })
  },
  data: () => ({
    filtering: false,
    show_settings: false,
    choose_filter: true,
    settings: {
      show_groups: [],
    }
  }),
  computed: {
    ...mapGetters('data', [
      'clips',
      'loading',
      'all_tags'
    ]),
    ...mapGetters('tag', [
      'tag_groups'
    ])
  },
  methods: {
    startSearch(e) {
      this.SET_LOADING(true)
      this.SET_CURRENT_FILTER(e)
      this.getFilterDataNoSave({ filter: JSON.stringify(e) })
        .then(e => {
          this.SET_DATA_CLIPS( e.clips.map(c => c.clips).flat() )
        })
        .catch(e => {
          alert(e)
        })
        .finally(() => {
          this.SET_LOADING(false)
          this.filtering = false
          this.choose_filter = false
        })
    },
    ...mapActions('filter', [
      'getFilterDataNoSave'
    ]),
    ...mapActions('tag', [
      'initTags'
    ]),
    ...mapMutations('data', [
      'SET_DATA_CLIPS',
      'SET_LOADING',
      'SET_CURRENT_FILTER'
    ])
  }
}
</script>